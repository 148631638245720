import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes }  from 'react-router-dom';
import profilePic from '../assets/profile.png';
import img1 from '../assets/img1.png';
import Footer from '../component/footer.js';
import Navbar from '../component/navbar.js';

const HeroPage = () => {
    const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-white flex flex-col px-4 sm:px-8 md:px-16 lg:px-24">
        <Navbar />
      <div className="text-left mt-8 md:mt-16">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-2">
            CARL BRYAN - VISUAL ARTIST
        </h1>
</div>
      
      {/* Image Grid Section */}
      <div className="gap-8 mt-8">
        <div className="grid grid-cols-1 gap-8">
          <img src={img1} alt="Artwork 1" className="object-cover" />
        </div>   
      </div>

      <div className="text-left mt-8 md:mt-16">
  <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
  “STRONG REALISTIC STROKES THAT CAPTURE THE MOVEMENT OF HIS SUBJECTS”
  </h1>
  <h3 className="text-lg text-justify font-normal mb-6">
  Carl Bryan Saldivar has made a name for himself in the UAE ART scene through his stunning murals and artworks earning himself a recognition in national and international platform
  </h3>
</div>
<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="mb-6 lg:mb-0">
          <img
            src={profilePic}  // replace `profilePic` with your actual image import or path
            alt="Art Gallery"
            className="w-50% h-auto rounded-lg shadow-lg"
          />
        </div>

        <div className="mt-14 lg:mb-0">
          <h2 className="text-gray-600 text-lg font-normal mb-4">Products</h2>
          <ul className="space-y-4">
            <li>
              <a href=" " onClick={() => navigate('/muralPaints')} className="text-black text-xl hover:text-gray-800 flex items-center justify-between">
                Mural Paintings <span className="text-xl">&gt;</span>
              </a> 
            </li>
            <li>
              <a href="#" className="text-black text-xl hover:text-gray-800 flex items-center justify-between">
                Oil & Acrylic On Canvas <span className="text-xl">&gt;</span>
              </a>
            </li>
            <li>
              <a href="#" className="text-black text-xl hover:text-gray-800 flex items-center justify-between">
                Personalized Bags & Shoes <span className="text-xl">&gt;</span>
              </a>
            </li>
            <li>
              <a href="#" className="text-black text-xl hover:text-gray-800 flex items-center justify-between">
                Exhibits & Workshops <span className="text-xl">&gt;</span>
              </a>
            </li>
            <li>
              <a href="#" className="text-black text-xl hover:text-gray-800 flex items-center justify-between">
                Digital Charcoals <span className="text-xl">&gt;</span>
              </a>
            </li>
            <li>
              <a href="#" className="text-black text-xl hover:text-gray-800 flex items-center justify-between">
                Colored Portraits <span className="text-xl">&gt;</span>
              </a>
            </li>
            <li>
              <a href="#" className="text-black text-xl hover:text-gray-800 flex items-center justify-between">
                Digital Caricatures <span className="text-xl">&gt;</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Footer/>
    </div>

  );
};

export default HeroPage;
