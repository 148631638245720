import React from 'react';
import Logo from '../assets/logo.png';

const Navbar = () => {
  return (
    <header className="bg-white py-8 px-4">
      <div className="container mx-auto flex items-center justify-between">
        {/* Profile Section */}
        <div className="flex items-center">
          <img
            src={Logo} // Replace with actual path to the profile picture
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
        </div>

        {/* Icons Section */}
        <div className="flex items-center space-x-4">
          <button className="text-gray-600 hover:text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.75V19.25M5.25 12H18.75" />
            </svg>
          </button>
          <button className="text-gray-600 hover:text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6H20M4 12H20M4 18H20" />
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
