import React from 'react';
import Logo from '../assets/logo.png';

const Footer = () => {
  return (
    <footer className="bg-white py-16">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between text-gray-700">
        <div className="text-sm mb-4 md:mb-0 flex items-center">
          <img src={Logo} alt="Logo" className="w-4 h-4 mr-2" /> {/* Replace with your logo */}
          <span>©carlbryan-arts.com</span>
        </div>
        <div className="flex space-x-4 text-sm items-center">
          <a href="#" className="hover:text-gray-900">LINKEDIN <span className="text-xl">&rarr;</span></a>
          <span>|</span>
          <a href="#" className="hover:text-gray-900">TIKTOK <span className="text-xl">&rarr;</span></a>
          <span>|</span>
          <a href="#" className="hover:text-gray-900">INSTAGRAM <span className="text-xl">&rarr;</span></a>
          {/* <span>|</span> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
