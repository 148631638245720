import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import img4 from '../assets/img4.png';
import img5 from '../assets/img5.png';
import img6 from '../assets/img6.png';
import img7 from '../assets/img7.png';
import img8 from '../assets/img8.png';
import img9 from '../assets/img9.png';

const MuralGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // Default sorting order
  const navigate = useNavigate(); // Initialize useNavigate

  const images = [img8, img3, img5, img4, img6, img7, img2, img9];

  // Sort images based on sortOrder
  const sortedImages = [...images].sort((a, b) => {
    const indexA = images.indexOf(a);
    const indexB = images.indexOf(b);
    return sortOrder === 'asc' ? indexA - indexB : indexB - indexA;
  });

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const goBack = () => {
    navigate('/'); // Navigate to home page
  };

  return (
    <div className="container mx-auto p-8">
      {/* Back Button */}
      <button
        onClick={goBack}
        className="mb-6 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        &larr; Back to Home
      </button>

      <h1 className="text-center text-3xl font-bold mb-12">Mural Paintings</h1>
      
      {/* Sorting Dropdown */}
      <div className="mb-6 text-center">
        <label htmlFor="sortOrder" className="mr-2">Sort by:</label>
        <select
          id="sortOrder"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        {sortedImages.map((image, index) => (
          <div
            key={index}
            className="rounded-lg overflow-hidden transform transition duration-500 hover:scale-105 cursor-pointer"
            onClick={() => handleImageClick(image)}
          >
            <img
              src={image}
              alt={`Mural ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>

      {/* Modal */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative">
            <button
              className="absolute top-2 right-2 text-white text-3xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Zoomed Mural"
              className="max-w-full max-h-full transform transition duration-500 scale-100 hover:scale-105"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MuralGallery;
