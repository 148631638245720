import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import HeroPage from '../src/component/heroPage.js';
import Footer from './component/footer.js';
import Navbar from './component/navbar.js';
import MuralGallery from './component/muralPaints.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/heroPage" />} />
        <Route path="/heroPage" element={<HeroPage />} />
        <Route path="/" element={<Navigate to="/footer" />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/" element={<Navigate to="/muralPaints" />} />
        <Route path="/muralPaints" element={<MuralGallery  />} />

        <Route path="/" element={<Navigate to="/navbar" />} />
        <Route path="/navbar" element={<Navbar />} />
      </Routes>
    </Router>
  );
}

export default App;
